import React, { useEffect } from 'react'
import { CloseButton } from '@local/do-secundo-closebutton'
import { RestaurantInfo } from '../RestaurantInfo/RestaurantInfo'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { ToastContainer } from 'react-toastify'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { Cart } from '../Cart/Cart'
import { useGetCart } from '../CartQuery/CartQuery'
import style from './CartPage.module.css'
import { isMobileSafari } from '../../browser_overrides'
import { useCart } from '@local/do-secundo-cart-provider'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { Loading } from '@toasttab/do-secundo-loading'
import { ShareCartButton } from '../ShareCart/ShareCartButton'

const { toastContainer, toastWrapper } = style

export const CartPageInner = ({
  availabilityLoading,
  cartLoading,
  restaurantInfoLoading,
  cartError,
  restaurantInfoError,
  getRestaurantPath,
  orderingAvailable
}) => {
  const { cart } = useGetCart()

  if (availabilityLoading || cartLoading || restaurantInfoLoading) {
    return (
      <div className='flex items-center justify-center h-24 pt-16'>
        <Loading variant='secondary' />
      </div>
    )
  }

  if (cartError || restaurantInfoError) {
    return (
      <div className='flex flex-col justify-center h-full mx-auto w-72'>
        <MessageError
          header='Unable to load your cart'
          message='Try refreshing the page, or contact the restaurant directly to order'
          buttonSecondary={{
            label: 'Refresh',
            onClick: () => window.location.reload()
          }}
        />
      </div>
    )
  }

  return (
    <>
      <div className={'text-left mx-6'}>
        <h3 className='mx-0 mr-4 type-headline-4 text-default sm:mt-10'>
          {cart?.metadata?.name || 'Your order'}
        </h3>
        {cart?.metadata?.description && (
          <div className={'type-default text-secondary'}>
            {cart?.metadata?.description}
          </div>
        )}

        <ShareCartButton />
      </div>
      <hr className={'mx-6 my-4 bg-darken-12'} />
      <div className={'pb-2'}>
        <RestaurantInfo
          orderingAvailable={orderingAvailable}
          showDeliveryAddress
          showShareCartButton
        />
      </div>
      <Cart
        menuLink={getRestaurantPath()}
        checkoutLink={getRestaurantPath('checkout')}
        orderingAvailable={orderingAvailable}
      />
    </>
  )
}

export const CartPage = () => {
  const { loading: availabilityLoading, orderingAvailable } = useAvailability()
  const { dispatch } = useCart()
  const { error: cartError, loading: cartLoading } = useGetCart()

  const { restaurantInfo, getRestaurantPath } = useRestaurant()
  const { error: restaurantInfoError, loading: restaurantInfoLoading } =
    restaurantInfo

  useEffect(() => {
    return () => {
      // when unmounting the CartPage, clear modification errors
      dispatch({ type: 'CLEAR_MODIFICATION_ERRORS' })
    }
  }, [dispatch])

  return (
    <div className={style.cartPage}>
      <ScrollToTop el={isMobileSafari ? document.body : undefined} />
      <div className={style.closeButton}>
        <CloseButton
          data-testid='close-button-cart-page'
          to={{
            pathname: getRestaurantPath(),
            search: ''
          }}
        />
      </div>
      <CartPageInner
        {...{
          availabilityLoading,
          cartLoading,
          restaurantInfoLoading,
          cartError,
          restaurantInfoError,
          getRestaurantPath,
          orderingAvailable
        }}
      />
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position={'top-center'}
      />
    </div>
  )
}
CartPage.propTypes = {}
