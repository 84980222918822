import { DeliveryInfo, Order, PaymentType } from './orders'

export interface FulfillmentInfo {
  diningOptionBehavior: DiningOptionBehavior
  fulfillmentDate: string
  deliveryInfo?: DeliveryInfo
  customLocationName?: string
  standardFulfillment?: boolean
}

export interface FulfillmentInfoRequest extends FulfillmentInfo {
  fastLinkName: string | undefined
}

export interface CartMetadataResponse {
  orderGuid?: string
  metadata: CartMetadata
}

export interface CartMetadata {
  name?: string
  description?: string
  fastLinkName: string | undefined
  customLocationName?: string
  standardFulfillment?: boolean
  companyName?: string
}

export interface Cart {
  guid: string
  sessionId: string
  restaurantGuid: string
  modifiedDate: string
  order: Order
  requestedFulfillmentDateTime?: string
  globalRewardInfo?: GlobalRewardInfo
  daasInfo?: DaasInfo
  ordermgmtInfo?: CartOrderMgmtInfo
  cartUpsellInfo?: CartUpsellInfo
  fulfillmentType?: FulfillmentType
  fulfillmentDateTime: string
  diningOptionBehavior: DiningOptionBehavior
  requiredQuoteTime?: number
  takeoutQuoteTime?: number
  deliveryQuoteTime?: number
  requiredPrepTime?: number
  takeoutPrepTime?: number
  deliveryPrepTime?: number
  preComputedTips?: PreComputedTip[]
  allowTipping?: boolean
  approvalRuleResults: CartOrderApprovalRuleResult[]
  paymentOptions: CartPaymentOptions
  warnings: ErrorMessage[]
  metadata: CartMetadata
}

export type DiningOptionBehavior = 'TAKE_OUT' | 'DINE_IN' | 'DELIVERY'

interface GlobalRewardInfo {}

interface CartOrderMgmtInfo {}

interface CartUpsellInfo {}

export type DeliveryProvider = 'TOAST' | 'DOORDASH' | 'POSTMATES'

export interface DaasInfo {
  diningOptionGuid: string
  provider?: DeliveryProvider
  deliveryFee?: number
  pickupTime?: string
  deliveryTime?: string
}

export type FulfillmentType = 'ASAP' | 'FUTURE'

export interface PreComputedTip {
  percent: number
  value: number
  isDefault: boolean
}

export enum ApprovalRuleType {
  ORDER_LT = 'ORDER_LT',
  ORDER_GT = 'ORDER_GT'
}

export interface CartOrderApprovalRuleResult {
  requiredAdjustment: number
  ruleType: ApprovalRuleType
  thresholdAmount: number
}

export interface CartPaymentOptions {
  atCheckout: { paymentType: PaymentType }[]
  uponReceipt: { paymentType: PaymentType }[]
}

export interface ErrorMessage {
  code: string
  message: string
  messageKey: string
}
