import React, { MutableRefObject, ReactNode, useCallback } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon, CloseIcon } from '@toasttab/buffet-pui-icons'
import { MenuItemDetails } from '@local/do-secundo-model'
import { useNestedModifiers } from '@local/do-secundo-modifiers'
import { ErrorComponent } from '@local/do-secundo-error'
import styles from './MenuItemModal.module.css'
import cx from 'classnames'
import { Form } from 'formik'

interface Props {
  onClose: () => void
  menuItemDetails: MenuItemDetails
  footer: ReactNode
  modalBodyRef: MutableRefObject<HTMLDivElement | null>
  error?: any
}

export const MenuItemModal = ({
  onClose,
  menuItemDetails,
  footer,
  modalBodyRef,
  error,
  children
}: React.PropsWithChildren<Props>) => {
  const { nestedModifier, popNestedModifier } = useNestedModifiers()
  const onBack = useCallback(() => {
    popNestedModifier()
  }, [popNestedModifier])

  return (
    <Modal
      size='xxl'
      isOpen
      onRequestClose={onClose}
      className='md:rounded-2xl'
    >
      <Form className={styles.modalContainer}>
        <div
          ref={modalBodyRef}
          className={cx(styles.content, 'md:rounded-2xl')}
        >
          <div className='absolute z-10 bg-white rounded-full right-2 top-2'>
            <IconButton
              testId='modal-close-button'
              icon={<CloseIcon aria-label='close' />}
              onClick={onClose}
              className='text-default'
            />
          </div>
          {menuItemDetails.imageUrl && !nestedModifier && (
            <div className={styles.imageWrapper}>
              <img
                src={menuItemDetails.imageUrl}
                alt={menuItemDetails.name}
                className={styles.itemModalImage}
              />
            </div>
          )}
          <div className={cx(styles.header, 'p-2 pr-12')}>
            {nestedModifier && (
              <IconButton
                icon={<ChevronLeftIcon aria-label='back' />}
                onClick={onBack}
                className='text-default'
              />
            )}
            <h3 className='py-1 pl-3 text-default type-headline-4'>
              {menuItemDetails.name}
            </h3>
          </div>
          {error && <ErrorComponent error={error} isActionable={false} />}
          {children}
        </div>
        <div className={styles.footer}>{footer}</div>
      </Form>
    </Modal>
  )
}
